<div class="ui-fluid">
  <div class="ui-label">
    <label [htmlFor]="item.id">
      {{ item.label }}
      <span *ngIf="item.required" class="required-indicator">*</span>
    </label>
    <app-base64-file-upload [id]="item.id" [item]="item" [formControl]="item.formControl"></app-base64-file-upload>
  </div>
  <app-form-validators [item]="item"></app-form-validators>
</div>

// Angular
import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// Models
import { Calendar } from '../../models';

@Component({
  selector: 'app-patch-calendar',
  templateUrl: './patch-calendar.component.html',
  styleUrls: ['./patch-calendar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PatchCalendarComponent),
      multi: true,
    },
  ],
})
export class PatchCalendarComponent implements ControlValueAccessor {
  /**
   * PrimeNG's Calendar only supports values passed in as Date. This
   * component converts a ISO string input to a Javascript Date object.
   *
   * https://www.primefaces.org/primeng/#/calendar
   */

  disabled: boolean;

  @Input() item: Calendar;
  @Input() localeData: any;

  private _value: Date;

  set value(val: Date) {
    this._value = val;
    if (this.onChange) {
      this.onChange(val);
    }
    if (this.onTouched) {
      this.onTouched(val);
    }
  }

  get value() {
    return this._value;
  }

  onTouched: CallableFunction;
  onChange: CallableFunction;

  constructor() {}

  registerOnChange(fn: CallableFunction): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: CallableFunction): void {
    this.onTouched = fn;
  }

  writeValue(val: any): void {
    if (!val) {
      this.value = undefined;
      return;
    }
    if (val.length <= 5) {
      // time only
      this.value = val;
    } else if (val instanceof Date) {
      this.value = val;
    } else if (val.includes('T')) {
      this.value = new Date(val);
    } else {
      const valSplit = val.split('-');
      this.value = new Date(valSplit[0], +valSplit[1] - 1, valSplit[2]);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}

// Shell Loader
import { ShellLoader } from './ShellLoader';

// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Configs
import { MicrofrontendConfigurationService } from './microfrontend-configuration.service';
import { ApplicationConfig } from './interfaces/shell-config';
import { Subscription } from 'rxjs';
import { APP_KEY, SHELL_DATA } from 'engage-common';

let appKey = '';

const shellDataToShare = {
  get appKey() {
    return appKey;
  },
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    {
      provide: APP_KEY,
      useFactory: () => appKey,
    },
    {
      provide: SHELL_DATA,
      useFactory: () => shellDataToShare,
    },
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  HTMLBodyInjection?: any;

  _sizeToBootStrapGridNumber = new Map()
    .set('quarter', 3)
    .set('third', 4)
    .set('half', 6)
    .set('two-thirds', 8)
    .set('three-quarters', 9)
    .set('full', 12);

  subscriptions: Subscription[] = [];

  constructor(
    private _microfrontendConfigurationService: MicrofrontendConfigurationService,
    private _route: ActivatedRoute,
    private _router: Router,
    public shellLoader: ShellLoader
  ) {}

  ngOnInit(): void {
    if (this.parseAppKeyInUrl()) {
      return;
    }

    this.subscriptions = [this.parseAppKeyInQueryParam()];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  parseAppKeyInQueryParam(): Subscription {
    return this._route.queryParams.subscribe((params) => {
      if (!params.key) {
        return;
      }

      this.lookupAppConfig(params.key);
    });
  }

  parseAppKeyInUrl(): boolean {
    if (location.search !== '') {
      return false;
    }

    const path = location.pathname.split('/').filter((key) => {
      // filter out reverse proxy paths
      return !!key && key !== 'engage' && key !== 'generic-open-clinic';
    });
    const appKey = path[0];
    this.lookupAppConfig(appKey, () => {
      this._router.navigate(path);
    });

    return true;
  }

  lookupAppConfig(key: string, cb?: () => void): void {
    this._microfrontendConfigurationService
      .lookupConfig(key)
      .subscribe((application: ApplicationConfig) => {
        (window as any).backendApiKey = key;
        appKey = key;

        this.HTMLBodyInjection = application.config?.htmlInjections;

        if (this.HTMLBodyInjection?.body) {
          document.getElementsByClassName('html-inject')[0].innerHTML = this.HTMLBodyInjection.body;
        }

        if (this.HTMLBodyInjection?.gtmScript) {
          let node = document.createElement('script');
          node.src = this.HTMLBodyInjection.gtmScript;
          node.type = 'text/javascript';
          node.async = true;
          window['dataLayer'] = window['dataLayer'] || [];
          window['dataLayer'].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
          document.getElementsByTagName('head')[0].appendChild(node);
        }

        if (this.HTMLBodyInjection?.gtagJsScript) {
          let node = document.createElement('script');
          node.src = this.HTMLBodyInjection.gtagJsScript;
          node.type = 'text/javascript';
          node.async = true;
          document.getElementsByTagName('head')[0].appendChild(node);
        }

        if (this.HTMLBodyInjection?.stripeScript) {
          // script node for function
          let functionNode = document.createElement('script');
          functionNode.innerHTML = this.HTMLBodyInjection.stripeScript.function;
          // script node for stripe source script and rewardful attribute
          let scriptNode = document.createElement('script');
          scriptNode.async = true;
          scriptNode.src = this.HTMLBodyInjection.stripeScript.scriptSrc;
          scriptNode.setAttribute(
            'data-rewardful',
            this.HTMLBodyInjection.stripeScript.dataRewardful
          );
          document.getElementsByTagName('head')[0].append(functionNode);
          document.getElementsByTagName('head')[0].append(scriptNode);
        }

        const shellConfig = { ...application.config, key: application.key };
        this.shellLoader.config = shellConfig;
        shellDataToShare['siteKey'] = this.shellLoader?.content?.siteKey;
        shellDataToShare['backendUrl'] = this.shellLoader?.content?.backendUrl;
        shellDataToShare['content'] = this.shellLoader?.content;

        if (this.HTMLBodyInjection?.gtagJsScript) {
          let node = document.createElement('script');
          node.src = this.HTMLBodyInjection.gtagJsScript;
          node.type = 'text/javascript';
          node.async = true;
          document.getElementsByTagName('head')[0].appendChild(node);
        }

        if (this.HTMLBodyInjection?.stripeScript) {
          // script node for function
          let functionNode = document.createElement('script');
          functionNode.innerHTML = this.HTMLBodyInjection.stripeScript.function;
          // script node for stripe source script and rewardful attribute
          let scriptNode = document.createElement('script');
          scriptNode.async = true;
          scriptNode.src = this.HTMLBodyInjection.stripeScript.scriptSrc;
          scriptNode.setAttribute(
            'data-rewardful',
            this.HTMLBodyInjection.stripeScript.dataRewardful
          );
          document.getElementsByTagName('head')[0].append(functionNode);
          document.getElementsByTagName('head')[0].append(scriptNode);
        }

        this.shellLoader.init().then(() => {
          if (!cb) {
            return;
          }

          cb();
        });
      });
  }

  sizeToBootStrapGridClass(size: string): string {
    return `col-${this._sizeToBootStrapGridNumber.get(size)}`;
  }
}

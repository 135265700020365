// angular
import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';

// services
import { BuilderService } from '@root/services/builder.service';
import { FormGroupService } from '@root/services/form-group.service';

// models
import { RootContainer } from '@shared/models';
import { FormElement } from '@shared/models/form-element';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root-container',
  templateUrl: './root-container.component.html',
  styleUrls: ['./root-container.component.scss'],
})
export class RootContainerComponent implements OnInit, OnDestroy {
  @Input() item: RootContainer;
  @Input() buildable: boolean = false;
  draggableElementIds: string[];

  styleSheet: CSSStyleSheet;

  subscriptions: Subscription[] = [];

  constructor(
    private builder: BuilderService,
    private fgs: FormGroupService,
    @Inject(DOCUMENT) private document: Document
  ) {
    const style = document.createElement('style');
    style.appendChild(document.createTextNode(''));
    document.head.appendChild(style);
    this.styleSheet = style.sheet as CSSStyleSheet;
  }

  onDragDrop(event) {
    this.builder.onDragDrop(event);
    this.resetStyles();
  }

  resetStyles() {
    for (let i = 0; i < this.styleSheet.cssRules.length; i++) {
      this.styleSheet.removeRule(i);
    }

    // add custom rules from rootcontainer
    this.item.customCssRules.forEach((rule) => {
      this.styleSheet.insertRule(rule.rule);
    });
  }

  ngOnInit() {
    if (this.buildable) {
      this.subscriptions = [
        ...this.subscriptions,
        this.builder.getDragDropEvents().subscribe(() => {}),
      ];
      this.onDragOut();
    }
    this.resetStyles();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  onComponentClick(item: FormElement, event?: Event) {
    if (!!event) {
      event.stopPropagation();
    }
    this.builder.lastElementSubject.next(item);
    this.resetStyles();
  }

  onDragOut() {
    this.subscriptions = [
      ...this.subscriptions,
      this.builder
        .getDraggableElementIds()
        .subscribe((ids) => (this.draggableElementIds = ids)),
    ];
  }
}

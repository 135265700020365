// Angular
import { Injectable, NgZone } from '@angular/core';

// Models
import { FormEventService } from '@root/services/form-event.service';
import { FormStateService } from '@root/services/form-state.service';

@Injectable({
  providedIn: 'root',
})
export class IframeService {
  eventSource: MessageEventSource;
  eventOrigin: any;

  public formJSON: object;
  public inputValues: object;
  public environment: object;
  public formId: string;

  constructor(
    private fss: FormStateService,
    private fes: FormEventService,
    private _zone: NgZone
  ) {
    this._zone.runOutsideAngular(() => {
      window.addEventListener('message', (event: MessageEvent) => {
        if (
          event.origin.includes('127.0.0.1') ||
          event.origin.includes('localhost') ||
          event.origin.includes('rightpathflow.com') ||
          event.origin.includes('rightpathengage.com') ||
          event.origin.includes('verto.ca') ||
          event.origin.includes('vertoflow.com') ||
          event.origin.includes('vertoclinic.com') ||
          event.origin.includes('carechart.ca') ||
          event.origin.includes('unity.local') ||
          event.origin.includes('smh.smhroot.net')
        ) {
          let message;
          try {
            message = JSON.parse(event.data);
          } catch {
            return;
          }

          if (message.actionType === 'input') {
            this.formJSON = message['formJSON'];
            this.inputValues = message['inputValues'];
            this.environment = message['environment'];
            this.eventSource = event.source;
            this.eventOrigin = event.origin;
            this.formId = message['iFrameSelector'];
          } else if (message.actionType === 'saved') {
            this.fss.formStateSubject.next('saved');
          } else if (message.actionType === 'error') {
            this.fss.formStateSubject.next('error');
          } else if (message.actionType === 'save-form') {
            this.fes.formExternalEventSubject.next('save-form');
          } else if (message.actionType === 'clear-form') {
            this.fes.formExternalEventSubject.next('clear-form');
          }
        }
      });
    });

    this.fes.getFormEvents().subscribe((event) => {
      this.postResult(event);
    });
  }

  /**
   * Sends JSON payload to parent container.
   */
  postResult(result: { eventType: 'submit' | string; data: object }) {
    result['formId'] = this.formId;
    if (this.eventSource) {
      (this.eventSource as any).postMessage(
        JSON.stringify(result),
        this.eventOrigin
      );
    }
  }
}

// angular
import { Component, OnInit } from '@angular/core';

// services
import { BuilderService } from '@root/services/builder.service';
import { FormEventService } from '@root/services/form-event.service';
import { FormGroupService } from '@root/services/form-group.service';

// components
import { FormElementComponent } from '@shared/components/form-element/form-element.component';

// models
import { FormElement } from '@shared/models';

@Component({ template: '' })
export class FormContainerComponent
  extends FormElementComponent
  implements OnInit
{
  public draggableElementIds: string[];

  constructor(
    fgs: FormGroupService,
    fes: FormEventService,
    public builder: BuilderService
  ) {
    super(fgs, fes);
  }

  ngOnInit() {
    this.builder.getDraggableElementIds().subscribe((ids) => {
      this.draggableElementIds = ids;
    });
  }

  onComponentClick(item: FormElement, event) {
    if (!!event) {
      event.stopPropagation();
    }
    this.builder.lastElementSubject.next(item);
  }

  onDragDrop($event): void {
    this.builder.onDragDrop($event);
  }
}

<ng-container *ngIf="buildable; else renderOnly">
  <div class="small-header">{{ item.name }}</div>
  <div
    cdkDropList
    [id]="item.id"
    (cdkDropListDropped)="onDragDrop($event)"
    [cdkDropListConnectedTo]="draggableElementIds"
    [cdkDropListData]="item"
    class="item-dropzone row no-padding"
  >
    <app-dynamic-component
      cdkDrag
      *ngFor="let child of item.children"
      [buildable]="true"
      [item]="child"
      [cdkDragData]="child"
      (click)="onComponentClick(child, $event)"
      class="col-sm-{{ child.getColSpan() }} col-md-{{ child.getColSpan() }} col-lg-{{ child.getColSpan() }}"
      [class.no-padding]="child.elementTypeName.includes('Container') || child.elementTypeName.includes('Fieldset')"
      [class.has-borders]="item.hasBorders"
    >
    </app-dynamic-component>
  </div>
</ng-container>

<ng-template #renderOnly>
  <div class="p-grid">
    <app-dynamic-component
      *ngFor="let child of item.children"
      class="child-item p-col-{{ child.getColSpan() }}"
      [item]="child"
      [ngClass]="{ buildable: buildable, hidden: child.hidden }"
      [class.no-padding]="child.elementTypeName.includes('Container') || child.elementTypeName.includes('Fieldset')"
      [class.has-borders]="item.hasBorders"
    >
    </app-dynamic-component>
  </div>
</ng-template>

import { Component } from '@angular/core';
import { ListFormComponentComponent } from '@shared/components/list-form-component/list-form-component.component';
import { ColoredSelectButton } from '@shared/models/colored-select-button';

@Component({
  selector: 'app-colored-select-button',
  templateUrl: './colored-select-button.component.html',
  styleUrls: ['./colored-select-button.component.scss'],
})
export class ColoredSelectButtonComponent extends ListFormComponentComponent {
  item: ColoredSelectButton;
  radioVal: any;

  clickRadio(event: Event, value: any) {
    if (!this.item.multiselect && !this.item.formControl.disabled) {
      event.preventDefault();

      if (this.radioVal !== value) {
        this.radioVal = value;
        this.item.formControl.patchValue(value);
        return;
      } else {
        this.radioVal = undefined;
        this.item.formControl.reset();
      }
    }
  }
}

// models
import { InputTextareaOptions } from '@shared/options';
import { FormComponent } from './form-component';

export class InputTextarea extends FormComponent {
  elementTypeName: string = 'InputTextarea';

  rows: number;
  cols: number;
  autoResize: boolean;
  resizable: boolean;

  placeholder: string;
  maxlength: string;

  constructor(options: InputTextareaOptions) {
    super(options);
    this.rows = options.rows;
    this.cols = options.cols;
    this.autoResize = options.autoResize;
    this.resizable = options.resizable;
    this.placeholder = options.placeholder || '';
    this.maxlength = options.maxlength;
  }

  toJSON(): object {
    const result = super.toJSON();
    return Object.assign(result, {
      rows: this.rows,
      cols: this.cols,
      autoResize: this.autoResize,
      resizable: this.resizable,
      placeholder: this.placeholder,
      maxlength: this.maxlength,
    });
  }

  getEditFormJSON(extraChildren: object[] = []): object {
    return super.getEditFormJSON(
      [
        {
          elementType: 'InputText',
          name: 'placeholder',
          required: false,
          label: 'Placeholder',
        },
        {
          elementType: 'InputText',
          name: 'maxlength',
          label: 'Character Limit',
          inputType: 'number',
        },
        {
          elementType: 'InputText',
          name: 'cols',
          pKeyFilter: 'pint',
          required: false,
          label: 'Cols',
        },
        {
          elementType: 'InputText',
          name: 'rows',
          pKeyFilter: 'pint',
          required: false,
          label: 'Rows',
        },
        {
          elementType: 'InputSwitch',
          name: 'autoResize',
          label: 'Auto Resize',
          required: false,
        },
        {
          elementType: 'InputSwitch',
          name: 'resizable',
          label: 'Resizable',
          required: false,
        },
        // @ts-ignore
      ].concat(extraChildren)
    );
  }
}

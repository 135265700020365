<ng-container *ngIf="buildable; else renderOnly">
  <p-accordion [attr.data-name]="item.name">
    <p-accordionTab [selected]="item.defaultOpen">
      <p-header>
        <span>{{ item.header }}</span>
        <span class="accordion-complete"> This form is marked as complete </span>
      </p-header>
      <div
        cdkDropList
        [id]="item.id"
        (cdkDropListDropped)="builder.onDragDrop($event)"
        [cdkDropListConnectedTo]="draggableElementIds"
        [cdkDropListData]="item"
        class="item-dropzone row accordion"
      >
        <app-dynamic-component
          *ngFor="let child of item.children"
          [buildable]="true"
          [item]="child"
          [cdkDragData]="child"
          (click)="onComponentClick(child, $event)"
          class="col-sm-{{ child.getColSpan() }}"
          cdkDrag
        >
        </app-dynamic-component>
      </div>
    </p-accordionTab>
  </p-accordion>
</ng-container>

<ng-template #renderOnly>
  <p-accordion [activeIndex]="item.isComplete ? -1 : item.defaultOpen ? 0 : -1" [attr.data-name]="item.name">
    <p-accordionTab [selected]="item.defaultOpen">
      <p-header>
        <span>{{ item.header }}</span>
        <span class="accordion-complete" *ngIf="item.isComplete"> This form is marked as complete </span>
      </p-header>
      <ng-container *ngIf="item.defaultOpen; else lazyLoad">
        <div class="p-grid accordion">
          <app-dynamic-component
            *ngFor="let child of item.children"
            class="p-col-{{ child.getColSpan() }}"
            [item]="child"
            [ngClass]="{ buildable: buildable, hidden: child.hidden }"
          >
          </app-dynamic-component>
        </div>
      </ng-container>
    </p-accordionTab>
  </p-accordion>
</ng-template>

<ng-template #lazyLoad pTemplate="content">
  <div class="p-grid accordion">
    <app-dynamic-component
      *ngFor="let child of item.children"
      class="p-col-{{ child.getColSpan() }}"
      [item]="child"
      [ngClass]="{ buildable: buildable }"
    >
    </app-dynamic-component>
  </div>
</ng-template>

// angular
import { Component, Input } from '@angular/core';

// models
import { FormElement } from '@shared/models';

// services
import { FormEventService } from '@root/services/form-event.service';
import { FormGroupService } from '@root/services/form-group.service';

@Component({ template: '' })
export class FormElementComponent {
  @Input() item: FormElement;
  @Input() buildable: boolean = false;

  constructor(private fgs: FormGroupService, public fes: FormEventService) {}

  emitEvent() {
    if (this.item.onChangeEventName) {
      this.fes.formEventSubject.next({
        eventType: this.item.onChangeEventName,
        data: this.item,
      });
    }
  }
}

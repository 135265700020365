import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { FormData, MoodRendererUnit, MoodRendererUnitType, MoodRenderFrame } from './renderer';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { valOrEval } from '../mood-utils/val-or-eval';

@Component({
  selector: 'mood-renderer',
  templateUrl: './mood-renderer.component.html',
  styleUrls: ['./mood-renderer.component.scss'],
})
export class MoodRendererComponent implements OnDestroy {
  @Input() set moodFrame(frame: MoodRenderFrame) {
    if (frame === this.currentFrame) {
      return;
    }

    this.currentFrame = frame;

    const formGroup = {};
    this.currentFrame.forEach((unit: MoodRendererUnit) => {
      const form: FormData = (unit as any).formData;
      if (form) {
        const validators = form?.validators?.validatorFunctions ?? [];

        formGroup[form.controlName] = new FormControl(form.currentValue ?? '', validators);
      }
    });

    this.form = new FormGroup(formGroup);

    this?.subscription?.unsubscribe?.();

    this.subscription = this.form.valueChanges.subscribe((data) => {
      this.formUpdated.emit(data);
    });
  }

  @Input() wrapperClasses: string[] = ['row', 'no-gutters', 'wrapper'];

  @Output() formUpdated = new EventEmitter();
  @Output() nestedFormUpdated = new EventEmitter();

  @ViewChildren(MoodRendererComponent) children: QueryList<MoodRendererComponent>;

  MoodRendererUnitType = MoodRendererUnitType;
  form: FormGroup;
  currentFrame: MoodRenderFrame = [];
  subscription: Subscription;

  valOrEval = valOrEval;

  constructor(private _cdr: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  classes(unit): string[] {
    let classes = [];

    classes.push(unit.gridClass || 'col-12');

    if (unit.classes && unit.classes.length > 0) classes = classes.concat(unit.classes);

    if (unit.alignment) classes.push(unit.alignment);

    if (unit.hidden && unit.hidden()) classes.push('hidden');

    return classes;
  }
}

// Angular
import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';

// Components
import { FormElementComponent } from '@shared/components/form-element/form-element.component';
import {
  ListFormComponent,
  ListOption,
} from '@shared/models/list-form-component';

// Services
import { FormEventService } from '@root/services/form-event.service';
import { FormGroupService } from '@root/services/form-group.service';
import { FormListOptionsService } from '@root/services/form-list-options.service';

@Component({
  selector: 'app-list-form-component',
  templateUrl: './list-form-component.component.html',
  styleUrls: ['./list-form-component.component.scss'],
})
export class ListFormComponentComponent
  extends FormElementComponent
  implements OnInit
{
  item: ListFormComponent;
  options: ListOption[];

  constructor(
    fgs: FormGroupService,
    fes: FormEventService,
    private flos: FormListOptionsService
  ) {
    super(fgs, fes);
  }

  ngOnInit() {
    this.getOptions();
    this.fes
      .getFormEvents()
      .pipe(filter((event) => event.eventType === 'edit_submit'))
      .subscribe(() => {
        this.getOptions();
      });
  }

  getOptions() {
    this.options = this.item.options;
    if (!!this.item.environmentOptions) {
      this.options = this.flos.getEnvironmentOptions(
        this.item.environmentOptions
      );
    } else if (!!this.item.remoteSource) {
      this.flos
        .getOptions(this.item.remoteSource, this.item.bearerTokenKey)
        .subscribe((options) => {
          this.options = options;
        });
    }
  }
}

<div class="ui-fluid ui-label" [attr.data-name]="item.name">
  <label [htmlFor]="item.id">{{ item.label }}<span *ngIf="item.required" class="required-indicator">*</span></label>
  <p-dropdown
    [options]="options"
    [formControl]="item.formControl"
    [filter]="item.filter"
    [filterBy]="item.filterBy"
    [placeholder]="item.placeholder"
    [showClear]="item.showClear"
    class="dropdown-white-space"
  ></p-dropdown>
</div>
<app-form-validators [item]="item"></app-form-validators>

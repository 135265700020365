<ng-container *ngIf="buildable; else renderOnly">
  <p-fieldset
    [attr.data-name]="item.name"
    [legend]="item.legend"
    [toggleable]="item.toggleable"
    [collapsed]="item.collapsed"
  >
    <div
      cdkDropList
      [id]="item.id"
      (cdkDropListDropped)="builder.onDragDrop($event)"
      [cdkDropListConnectedTo]="draggableElementIds"
      [cdkDropListData]="item"
      class="item-dropzone row drag-placeholder"
    >
      <app-dynamic-component
        *ngFor="let child of item.children"
        [buildable]="true"
        class="child-item col-sm-{{ child.getColSpan() }} col-md-{{ child.getColSpan() }} col-lg-{{
          child.getColSpan()
        }}"
        [item]="child"
        [cdkDragData]="child"
        (click)="onComponentClick(child, $event)"
        cdkDrag
      >
      </app-dynamic-component>
    </div>
  </p-fieldset>
</ng-container>
<ng-template #renderOnly>
  <p-fieldset [legend]="item.legend" [toggleable]="item.toggleable" [collapsed]="item.collapsed">
    <div class="p-grid">
      <app-dynamic-component
        *ngFor="let child of item.children"
        class="p-col-{{ child.getColSpan() }}"
        [item]="child"
        [ngClass]="{ buildable: buildable, hidden: child.hidden }"
      >
      </app-dynamic-component>
    </div>
  </p-fieldset>
</ng-template>

// Angular
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

// Models
import { FormElement } from '@shared/models/form-element';
import { RootContainer } from '@shared/models/root-container';

@Injectable({
  providedIn: 'root',
})
export class BuilderService {
  public lastDroppedElement: FormElement;

  public dragDropEvents: BehaviorSubject<CdkDragDrop<any>>;
  public onDragDropEvent: Subject<boolean>;

  lastElementSubject: BehaviorSubject<FormElement>;
  public rootSubject: BehaviorSubject<RootContainer>;
  private draggableElementIds: BehaviorSubject<string[]>;

  constructor() {
    this.dragDropEvents = new BehaviorSubject(null);
    this.lastElementSubject = new BehaviorSubject(null);
    this.rootSubject = new BehaviorSubject(null);
    this.draggableElementIds = new BehaviorSubject([]);
    this.onDragDropEvent = new Subject();
  }

  getDraggableElementIds(): Observable<string[]> {
    return this.draggableElementIds.pipe(map((ids) => ids.slice().reverse()));
  }

  getRoot(): Observable<RootContainer> {
    return this.rootSubject.pipe(filter((root: RootContainer) => !!root));
  }

  updateRoot(root: RootContainer): void {
    this.rootSubject.next(root);
  }

  onDragDrop(event: CdkDragDrop<any>) {
    this.dragDropEvents.next(event);
  }

  getDragDropEvents() {
    return this.dragDropEvents.pipe(
      filter((event) => event !== null),
      tap((event) => {
        if (event.previousContainer.data.tools) {
          this.onToolbarDrop(event);
        } else {
          this.onFormElementMove(event);
        }
        this.draggableElementIds.next(this.rootSubject.value.getContainerIds());
      })
    );
  }

  onLastElementChange() {
    return this.lastElementSubject.pipe(filter((element) => element !== null));
  }

  onToolbarDrop(event: CdkDragDrop<any>): void {
    this.onDragDropEvent.next(true);
    this.lastDroppedElement = this.cloneToList(
      event.previousContainer.data.tools,
      event.container.data.children,
      event.previousIndex,
      event.currentIndex
    );
    this.lastElementSubject.next(this.lastDroppedElement);
  }

  onFormElementMove(event: CdkDragDrop<any>): void {
    this.onDragDropEvent.next(true);
    if (event.container === event.previousContainer) {
      moveItemInArray(
        event.container.data.children,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data.children,
        event.container.data.children,
        event.previousIndex,
        event.currentIndex
      );
    }

    this.lastElementSubject.next(event.item.data);
  }

  onFormElementTrash(event: CdkDragDrop<any>): void {
    this.onDragDropEvent.next(true);
    event.previousContainer.data.children.splice(event.previousIndex, 1);
  }

  /**
   * Clones item from one array to another. Modifying transferArrayItem from cdk utils found here
   * https://github.com/angular/material2/blob/master/src/cdk/drag-drop/drag-utils.ts
   *
   */
  private cloneToList<T = any>(
    palette: Array<{ tool: FormElement; name: string }>,
    targetArray: FormElement[],
    currentIndex: number,
    targetIndex: number
  ): FormElement {
    const to = Math.max(0, Math.min(targetIndex, targetArray.length));

    if (palette.length) {
      const clonedElement = palette[currentIndex].tool.clone();
      // insert cloned element at the index `to`
      targetArray.splice(to, 0, clonedElement);

      return clonedElement;
    }
  }
}

// Angular
import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

// Markdown
import { Marked } from '@ts-stack/markdown';

// Components
import { FormElementComponent } from '@shared/components/form-element/form-element.component';

// Models
import { MarkdownText } from '@shared/models';

// Services
import { FormEventService } from '@root/services/form-event.service';
import { FormGroupService } from '@root/services/form-group.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-markdown-text',
  templateUrl: './markdown-text.component.html',
  styleUrls: ['./markdown-text.component.scss'],
})
export class MarkdownTextComponent
  extends FormElementComponent
  implements DoCheck, OnDestroy, OnInit
{
  item: MarkdownText;
  parsedMarkdown: SafeHtml;

  constructor(
    fgs: FormGroupService,
    public fes: FormEventService,
    public sanitizer: DomSanitizer,
    private _ngZone: NgZone,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    super(fgs, fes);
  }

  parseMarkdown(text: string) {
    return Marked.parse(text);
  }

  updateMarkdownSubscription: Subscription;
  updateMarkdownSubject = new Subject();
  ngOnInit(): void {
    this.updateMarkdownSubscription = this.updateMarkdownSubject
      .pipe(debounceTime(100))
      .subscribe(() => {
        this.parsedMarkdown = this.sanitizer.bypassSecurityTrustHtml(
          Marked.parse(this.item.markdownText)
        );
      });
    this.updateMarkdownSubject.next();

    this._ngZone.runOutsideAngular(() => {
      this.parsedMarkdown = this.sanitizer.bypassSecurityTrustHtml(
        Marked.parse(this.item.markdownText)
      );
    });

    this._changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.updateMarkdownSubscription.unsubscribe();
  }

  ngDoCheck(): void {
    this._ngZone.runOutsideAngular(() => {
      this.updateMarkdownSubject.next();
    });
  }
}

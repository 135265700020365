// angular
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

// rxjs
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class FormGroupService {
  private formSubject: BehaviorSubject<FormGroup> =
    new BehaviorSubject<FormGroup>(null);

  constructor() {}

  getFormGroup(): Observable<FormGroup> {
    return this.formSubject.pipe(filter((form: FormGroup) => !!form));
  }

  updateFormGroup(formGroup: FormGroup): void {
    this.formSubject.next(formGroup);
  }
}
